// Here you can add other styles
// .cCardFill {
//   display: block;
// }

/* https://dev.to/rafacdomin/creating-floating-label-placeholder-for-input-with-reactjs-4m1f */

.float-label {
  display: flex;
  flex-direction: column; 
  position: relative;
}

.float-label input  {  
  /* input içi */
  outline: 0;
  border: 1px solid #ddd; 
  background: #fff;  
  padding: 14px 16px 0px 2px;
  color: black;
} 
.float-label select {  
  /* select içi */
  outline: 0;
  border: 1px solid #ddd; 
  background: #fff;  
  padding: 14px 16px 0px 2px;
  color: black;
}
.float-label textarea {  
  /* input içi */
  outline: 0;
  border: 1px solid #ddd; 
  background: #fff;  
  padding: 14px 16px 0px 2px;
  color: black;
} 

.float-label label { 
  /* input içinde label */
  padding: 3px 3px 0px 2px;
  color: #999; 
 
  pointer-events: none; 

  position: absolute;
  transform: translate(0, 3px) scale(1);

  transform-origin: top left;
  transition: all 0.2s ease-out;
}
/* 
.float-label:focus-within label {
  transform: translate(0, 1px) scale(0.75);
} */

.float-label .Active {
  /* label küçüldüğünde */
  padding: 0px 1px 0px;
  transform: translate(0, 1px) scale(0.75);  
}

// LOADING PANEL
.loader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color:rgba(255, 255, 255, 0.3);
  display: grid;
  place-items: center;
  z-index: 9999;
}

.loader {
  border: 16px solid #e3e3e3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  animation: spin 1s linear infinite; 
}

.loader-text {  
  margin-top: 22px;
  width: 100%;
  font-size: large; 
  text-align: center;
  padding-right: 50px;
  font-weight: bold;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.form-control[readonly] {
  background-color: #fff;
}


// Group FIELDSET
FIELDSET {
  margin: -7px 0px 0px 0px;
  border: 1px solid #ddd;
  padding: -3px 0px 0px 3px;    
  border-radius: 4px;
}
LEGEND {
  margin: 1px;
  z-index: 0;
  width: min-content;
  font-size: smaller; 
}